/* .reports-container {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
} */

.reports-navbar {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 10px 0;

}