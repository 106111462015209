.activeProjects-mainDiv {
  background: #EEEEEE;
  padding: 10px;
  width: 100%;
  border-bottom-left-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-container {
  background-color: white;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

}

.project-inner-container {
  margin: 18px;
  padding: 0.5rem;
  /* box-shadow: 1px 1px 4px rgb(44, 44, 44); */
}

.business-unit-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.project-nav {
  padding: 0 1rem;
}

.des-cost-1 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.des-cost-2 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.des-cost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}

.description {
  font-size: medium;
}

.category {
  flex: 1;
}

.p {
  color: grey;
  font-weight: 500;
  
}

.span {
  color: black;
  font-weight: 500;
}

.box1-options-project {
  width: 200px;
  border-radius: 5px;
  border: 2px solid #0056b3;
  color: #0056b3;
  padding: 4px;
  cursor: pointer;
}

.add-project-button {
  font-size: 0.8rem;
  padding: 7px;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  border: none;
  margin: 0px 0px 0px 8px;
  cursor: pointer;
}

.dates-staff-split {
  display: flex;
}