.week-view-navbar {
  margin: 10px;
  padding: 8px;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.TimesheetNav-mainDiv {
  background: #EEEEEE;
  padding: 20px 10px;
  width: 100%;
  border-bottom-left-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TimesheetNav-Container {
  padding: 10px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.outflownavbarTimesheet-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}