/* .App {
  text-align: center;
} */
@import "./assest/common.css";
.Main-container {
  display: flex;
  height: 90vh;
  /* background-color: #EEEEEE */
}


/* removing the default margin from the p tag */
p { 
 margin-bottom: 0;
} 

.center-table-div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.even-bg {
  background-color: #F8F8F8; 
}

.odd-bg {
  background-color: #ffffff; 
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}