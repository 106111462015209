.new-event-tag {
  padding: 0px 0px 10px 0px;
  cursor: pointer;
  margin: 2px;
}
.add-button {
  align-items: end;
  background-color: white;
  color: #0056b3;
  border: none;
  border-radius: 5px;
  margin-right: 0px;
  cursor: pointer;
}
.add-button-container {
  margin-left: 1px;
}
#event-tag-title {
  margin-bottom: 2px;
}
