.superadmin table th{
    font-weight: 600;
    max-width: 150px;
}
.superadmin table tbody tr td{
    /* max-width: 40px; */
    overflow-x: auto;
    overflow-y: auto;
}
svg{
    font-size: 10px;
}