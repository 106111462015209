.readytosendtoXERO-box1 {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.readyToSentXero_bill{
  padding: 0 6px;
  margin: 6px 0;
}
.send-to-xero-buttons {
  display: flex;
  justify-content: flex-end;
  align-items:center;
  gap: 10px;
  padding: 6px;
}

.sendToXero-rec-table{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.send-to-xero-approved-button {
  font-size: 0.9rem;
  color: rgb(21, 175, 21);
  font-weight: 500;
}
.send-to-xero button {
  background-color: #0056b3;
  padding: 4px 16px;
  font-size: 0.9rem;
  color: white;
  border-radius: 4px;
  border: none;
}

.link-active {
  color: #0056b3;
  /* background: orange; */
}
.link {
  color: black;
}
/* .container-connect-to-xero {
  padding: 20px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  width: 210px;
  margin: 15px 0px 0px 15px;
} */
.rec-inv-connect-to-xero {
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 5px;
  border: none;
  font-size: 1rem;
  padding: 6px 16px;
  background-color: #0056b3;
  color: white;
  border-color: 3px solid #0056b3;
  border-radius: 4px;
  cursor: pointer;
}
.Button-send-invoice {
  cursor: pointer;
}
