/* .remarks-container {
  margin: 18px;
} */
.remarks1 button {
  font-size: 1rem;
  font-weight: bold;
  margin: 15px 0px 0px 0px;
  color: #0056b3;
  background-color: white;
  border: none;
  margin: 4px;
}
.remarks1 button:hover {
  cursor: pointer;
}
.remarks2 {
  cursor: pointer;
  margin-left: 8px;
  font-size: 0.9rem;
}
.remarks2 span {
  font-weight: 500;
}

.redirect-button-container {
  width: 180px;
  margin-left: 800px;
}

.redirect-button-container button {
  color: white;
  background-color: #0056b3;
  border: none;
  border-radius: 5px;
  padding: 6px;
}
.redirect-button-container button:hover {
  cursor: pointer;
}
