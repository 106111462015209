.content-mainDiv {
  background: #EEEEEE;
  padding: 10px;
  width: 100%;
  border-bottom-left-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  background-color: white;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.tab_badge {
  background-color: #0056b3;
  color: white;
  padding: 4px 8px;
  text-align: center;
  font-size:9px;
  border-radius: 50%;
}