.Login-container {
  /* background-image: url("https://uat.refresh.controlfms.com/resources/assets/img/login/refresh/video-login-bg-img.webp"); */
  background-image: url("./Login.jpg");
  height: 100vh;
  width: 100%;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first-box-central-name {
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif;
  font-size: 38px;
  font-weight: 600;
  color: #1b1b1b;
  text-align: center;
  margin: 0;
  padding: 0;
}

#logo-h1 {
  padding: 0;
}

/* .logo-img {
  width: 300px;
  height: 300px;
} */
.Login-semi-container {
  /* position: absolute;
  top: 45%;
  left: 50%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  /* padding: 1rem; */
  padding: 40px 10px;
  width: auto;
  /* height: 335px; */
  /* transform: translate(-50%, -50%); */
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.6);
}

.Login-semi-container button {
  padding: 10px;
  border: none;
  font-size: 16px;
  width: 200px;
  background-color: #0056b8;
  color: #fff;
  text-align: center;
  /* margin-top: 160px; */
  border-radius: 10px;
  cursor: pointer;
}

.sign-in-button {
  text-align: center;
  margin-top: 20px;
  /* padding: 40px 0px; */
}
