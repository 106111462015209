.outflownavbar-container {
  display: flex;
  margin: 15px 0px 15px 0px;
  column-gap: 30px;
}
.outflownavbar-container-divitemsize {
  font-size: 1rem;
}
hr {
  border-color: 2px solid rgb(255, 255, 255);
}
.link-active{
  color:#0056b3;
  /* background: orange; */
  border-bottom: 1px solid #0056b3;
}
.link{
  color:black
}