.outflowapprovalbill-box1 {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.outflowapproval-container-box {
  margin: 20px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 15px;
}
.director-approval-buttons {
  display: flex;
  column-gap: 10px;
  /* margin: 20px 0px 20px 700px; */
  justify-content: flex-end;
  align-items: center;
}
.director-approval {
  color: orange;
  font-size: 0.9rem;
}
.director-approval-refuse {
  text-decoration: none;
  border: none;
  box-shadow: 1px 1px 4px #0056b3;
  border: 1px solid #0056b3;
  font-size: 0.8rem;
  padding: 4px;
  color: #0056b3;
  background-color: white;
  border-radius: 5px;
}
.director-approval-approve {
  text-decoration: none;
  background-color: #0056b3;
  font-size: 0.8rem;
  padding: 6px;
  color: white;
  border-radius: 5px;
  border: none;
}
.director-approval-bill table tr th{
  font-weight: 600;
}
.director-approval-bill table tr td{
  font-weight: 500!important;
}