form label {
    display: inline-block;
    font-size: 12px;
}

.form-control {
    width: 100%;
}
.text-danger{
    color: red;
    font-size:12px;
}
.employee-form input[type='radio'],
.employee-form input[type='checkbox'] {
    width: 10%;
}

.employee-form [type="radio"]:checked,
.employee-form [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.employee-form [type="radio"]:checked+label,
.employee-form [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.employee-form [type="radio"]:checked+label:before,
.employee-form [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #aeacac;
    border-radius: 100%;
    background: #fff;
}

.employee-form [type="radio"]:checked+label:after,
.employee-form [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #0056b3;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.employee-form [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.employee-form [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.employee-form .border {
    border: 1px solid #aeacac;
    border-radius: 10px;
    padding: 5px 10px;
}

.employee-form [type="checkbox"]:checked+label,
.employee-form [type="checkbox"]:not(:checked)+label {
    line-height: 20px;
    display: inline-block;
    font-weight: 400;
}