.remarks2 span {
  font-weight: 500;
}

.remarks2 {
  font-size: 0.9rem;
}

.director-chat-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.chat-button {
  margin-right: 10px;
  text-decoration: none;
  background-color: #0056b3;
  font-size: 0.8rem;
  padding: 6px;
  color: white;
  border-radius: 5px;
  border: none;
}

.resolve-button {
  text-decoration: none;
  border: none;
  box-shadow: 1px 1px 4px #0056b3;
  border: 1px solid #0056b3;
  font-size: 0.8rem;
  padding: 4px;
  color: #0056b3;
  background-color: white;
  border-radius: 5px;
}

/* edit form */
.editForm_Content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 1rem;
}

.editForm_fieldContent {
  width: 60%;
}

.editForm_fields{
  padding: 10px;
}

.editForm_fieldContent input{
  width: 90%;
  border: 1px solid #cccccc;

}