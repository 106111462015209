.create-event-button {
  border: 1px solid #ccc;
  /* padding: 0.5rem 1rem; */
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.create-event-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.create-event-button img {
  width: 1.8rem;
  height: 1.8rem;
}

.create-event-button span {
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
}
