.curr_container{
  display: flex;
  justify-content:center;
  align-items:flex-start;
  width: 100%;
  background-color: #EEEEEE;
}

.curr_mainDiv {
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  gap: 1rem;
  height: 86vh;
  overflow-y: auto;
  width: 100%;
  margin: 12px;
  background-color: white;
  border-radius: 3px;
}

.curr_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.curr_form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
}

.curr_head {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.curr_inputs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.curr_btn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}

label {
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

input,select {
  width: 80%;
  padding: 6px;
  border: 1px solid #cccccce5;
  border-radius: 3px;
}

.currency-button {
  background-color: #0056b3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.currency-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  input {
    width: 100%;
    /* Make the input element width 100% */
  }
}