.readytosendtoXEROpayinv-box1 {
  margin: 20px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.sendpayinvtoxero button {
  background-color: #0056b3;
  padding: 4px 16px;
  font-size: 0.9rem;
  color: white;
  border-radius: 4px;
  border: none;
}

.filter-by-org {
  margin: 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 3px;
  width: 270px;
  display: flex;
  gap: 10px;
}

.filter-by-org select {
  color: #0056b3;
  padding: 5px;
  font-size: 0.6rem;
  font-weight: bold;
  border: 1px solid #0056b3;
  border-radius: 2px;
  /* margin-left: 10px; */
}

.filter-by-org select option {
  font-weight: bold;
}

.send-to-xero-pay-inv {
  margin: 20px;
}

.send-to-xero-pay-inv button {
  background-color: #0056b3;
  padding: 4px 16px;
  font-size: 0.9rem;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.pay-connect-to-xero {
  /* margin-top: 15px;
  margin-right: 10px;
  margin-left: 5px; */
  cursor: pointer;
  border: none;
  font-size: 1rem;
  padding: 6px 16px;
  background-color: #0056b3;
  color: white;
  border-color: 3px solid #0056b3;
  border-radius: 4px;
}

.send-to-xero-container {
  padding: 16px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.filter-by-org {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.org-select-dropdown {
  width: 300px;
}

.table-container {
  margin-top: 16px;
}

.MuiTableCell-root {
  font-size: 12px;
  font-weight: 600;
}

.MuiTableRow-root:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.MuiTableRow-root:nth-of-type(even) {
  background-color: #ffffff;
}

.send-to-xero-button {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}