.dir_bill_apprvd_data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 4rem;
}

.dir_bill_apprvd_data_div1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.dir_bill_apprvd_data_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  padding: 10px;

}

.dir_bill_apprvd_data_div_field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.field_div_data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.directorbillapproved-box1 {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

.director-approved-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  color: rgb(21, 175, 21);
  font-weight: 500;
}

.directorbillapproved-box1 table tr,
th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}