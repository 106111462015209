.outflowbill-button-searchbox{
  margin: 10px 0;
}

.outflowbill-main-button-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0 10px 10px 0;
}
.outflowbill-box1 {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.outflowform-button-bill {
  padding: 8px 16px;
  background-color: #0056b3;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 18px;
}
.outflowbill-container-box {
  margin:1rem 0px;
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); */
  /* padding: 9px; */
}
.outflow-invoice-number {
  color: #0056b3;
  font-weight: bold;
  cursor: pointer;
}
.pdfLogo-img img {
  width: 30px;
  height: 30px;
}
.tags {
  color: grey;
}
.tags1 {
  font-weight: 500;
}
.outflowbill-invoice {
  display: flex;
  flex-direction:column ;
  justify-content: center;
  align-items: center;
}
.field {
  display: flex;
  padding: 0;
}
.row-flex {
  display: flex;
  column-gap: 40px;
}
.row-flex1 {
  margin-top: -22px;
  display: flex;
  column-gap: 40px;
  margin-left: 140px;
}
p {
  font-size: 0.8rem;
}
.outflowbill-3button {
  background-color: white;
  color: #0056b3;
  border: 2px solid #0056b3;
  border-radius: 4px;
  padding: 6px 12px;
  box-shadow: 1px 1px 4px #0056b3;
  font-size: 0.8rem;
}
.outflowbill-3button:hover {
  color: none;
  cursor: pointer;
}


.table-details-bill{
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}
.table-details-bill-left{
  margin: 10px;
}
.table-details-bill-right{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}
.field-col{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.right-field{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.even-bg {
  background-color: #F8F8F8; 
}

.odd-bg {
  background-color: #ffffff; 
}