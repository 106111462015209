.addProjectform-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addPro-plus-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem;
  flex-direction: column;
  gap: 1rem;
}

#split-add {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  width: 100%;
}

#add {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.outflowform-cancel {
  cursor: pointer;
}

.outflowform-button-cancel {
  cursor: pointer;
}
.addProjectform-container .flex{
  display: flex;
  margin: 20px;
  /* justify-content: center; */
  column-gap: 20px;
}
.addProjectform-container input,select {
  width: 100%;
  padding: 6px;
  border: 1px solid #cccccce5;
  border-radius: 3px;
}
/* .addProjectform-container table th,td{
  border: none!important;
} */
.split-input{
  width: 80%!important;
}
.addProjectform-container .p-flex{
  display: flex;
  margin: 10px 0px;
  /* justify-content: center; */
  column-gap: 20px;
}