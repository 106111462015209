.super-container {
    background-color: #f1f1f1;
    min-height:80vh;
}

.buttons {
    display: flex;
    gap: 15px;
    /* justify-content: space-between; */
}
h3{
    font-size: 20px;
}
.p-10{
    padding: 10px;
}