/* CSS for Events Component */
.events-container {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events-form {
  background-color: white;
  z-index: 999;
  border-radius: 0.5rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 25%; /* Adjust the width as needed */
}

.events-header {
  background-color: #f0f0f0; /* Equivalent to bg-gray-100 */
  padding: 0.5rem 1rem; /* Equivalent to px-4 py-2 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.events-header-icon {
  color: #888; /* Equivalent to text-gray-400 */
  font-size: 1.5rem; /* Adjust the font size as needed */
  cursor: grab; /* Equivalent to cursor-pointer */
}

.events-header-button {
  background: none;
  border: none;
  cursor: pointer;
}

.events-header-button-icon {
  color: #888; /* Equivalent to text-gray-400 */
  font-size: 1.5rem; /* Adjust the font size as needed */
  cursor: pointer;
}
.Time {
  display: flex;
}
.input-tag {
  border: 0;
  color: #666666;
  font-size: 1rem; /* You can adjust this value as needed */
  font-weight: 300;
  width: 100%;
  border-bottom: 2px solid #cccccc;
  outline: none;
  transition: border-color 0.3s;
  cursor: pointer;
}

.addEvent-field-div{
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1rem;
}

.select-tag {
  cursor: pointer;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 400;
  height: 50px;


  /* border-radius: 15px; */
}
.date-time {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-direction: column; */
  margin: 5px 0px 5px 15px;
}
.date-time p {
  font-size: medium;
}
.segment-input {
  display: flex;
  justify-content: center;
  align-items:center;
  /* flex-direction: column; */
  width: 90%;
}
.bookmark-color {
  display: flex;
  gap: 1rem;
  margin: 5px 0px 5px 15px;
}
.bookmark-color span {
  font-size: 1.8rem;
  /* margin-right: 15px; */
}
.timingInput {
  height: 1rem;
  width: 5rem;
  margin-top: 10px;
  margin-right: 15px;
}

.schedule-icon {
  margin-right: 15px;
  margin-left: 15px;
}

.footer-save {
  margin: 0.8rem;
}
.footer-save button {
  background-color: #0056b3;
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
  padding: 5px 8px 5px 8px;
  margin-top: 10px;
  cursor: pointer;
}

input[type="text"]:focus {
  border-color: #0056b3;
}
.material-icons-schedule-icon {
  color: grey;
  font-size: 80px;
}

.horizontal-container {
  display: flex;
  flex-direction: row; /* Display items horizontally */
  align-items: center; /* Center items vertically */
  gap: 6px; /* Adjust the spacing between items as needed */
}

/* Rest of your CSS styles */

#Color-coding {
  width: 1.5rem; /* 6/4 = 1.5, you can adjust the size as needed */
  height: 1.5rem; /* 6/4 = 1.5, you can adjust the size as needed */
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff; /* Replace with the actual text color for white */
  font-size: 0.875rem; /* Adjust the font size as needed */
  cursor: pointer;
}
.material-icons-check {
  color: white;
}

.bg-indigo-500 {
  background-color: #6c63ff; /* Replace with the actual color value for indigo */
}

.bg-gray-500 {
  background-color: #b7950b; /* Replace with the actual color value for gray */
}

.bg-green-500 {
  background-color: #117864; /* Replace with the actual color value for green */
}

.bg-blue-500 {
  background-color: #3498db; /* Replace with the actual color value for blue */
}

.bg-red-500 {
  background-color: #cd5c5c; /* Replace with the actual color value for red */
}

.bg-purple-500 {
  background-color: #a569bd; /* Replace with the actual color value for purple */
}
