/* .main-div {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.left-div {
  font-size: 55px;
  margin-left: 40px;
  padding: 12px;
  font-weight: 500;
  color: #0056b3;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.right-div {
  display: flex;
  margin-right: 70px;
}
.inner-left-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-right-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-div div {
  padding: 3px;
}
.inner-right-div img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 5px;
}
hr {
  border-color: rgb(255, 255, 255);
} */

.main-div {
  /* margin-top: 15px; */
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
  align-items: center;
}

.left-div {
  font-size: 1.5rem;
  margin-left: 28px;
  padding: 12px;
  font-weight: 500;
  color: #0056b3;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.right-div {
  display: flex;
  margin-right: 40px;
  /* gap: 8px; */
}

.inner-left-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-right-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.inner-right-div img {
  display: block;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.sign-out-button {
  font-size: 0.9rem;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  height: 1.8rem;
  padding: 6px;
  margin: 22px 0px 0px 8px;
}
/* Media Queries for Responsive Design */

@media only screen and (max-width: 768px) {
  .left-div {
    font-size: 35px; /* Decrease the font size for smaller screens */
    margin-left: 20px; /* Adjust the margin for smaller screens */
    padding: 8px; /* Adjust the padding for smaller screens */
  }

  .right-div {
    margin-right: 20px; /* Adjust the margin for smaller screens */
  }

  .inner-right-div img {
    height: 40px; /* Decrease the image size for smaller screens */
    width: 40px; /* Decrease the image size for smaller screens */
    margin: 3px; /* Adjust the margin for smaller screens */
  }
}
