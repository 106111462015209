.view-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}
.view-navbar select{ 
  font-size: 16px;
  height: 2rem;
  border: 1px solid black;
  border-radius: 3px;
}
