.xero-box1 {
  padding: 20px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  width: 310px;
  margin: 150px 0px 0px 300px;
}
.Button-connect-to-xero {
  margin-right: 10px;
  margin-left: 5px;
  border: none;
  font-size: 1rem;
  padding: 6px 16px;
  color: #0056b3;
  box-shadow: 1px 1px 4px #0056b3;
  border-color: 3px solid #0056b3;
  background-color: white;
  border-radius: 4px;
}
.Button-send-invoice {
  text-decoration: none;
  border: none;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 6px 16px;
}
