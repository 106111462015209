.outflowapprovalbill-box1 {
  padding: 5px 20px 20px 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.outflowapproval-container-box {
  margin: 20px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.outflowApprovalbill_div{
}

.table-details-bill-right{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}
.right-field-col{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.approval-await {
  padding: 6px;
  font-size: 0.9rem;
  color: orange;
  font-weight: 500;
  text-align: end;
}
.td-data {
  position: relative;
}
.span-data {
  position: absolute; /* Position the <span> absolutely within the <td> */
  right: 0;
  margin-right: 10px;
}
