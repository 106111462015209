.billProduct th {
  border: 1px solid lightgrey;
  padding: 2px;
  width: 150px;
  height: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
}
.billProduct table {
  margin-left: 20px;
  border-collapse: collapse;
}
.billProduct td {
  border: 1px solid lightgrey;
  /* padding: 8px; */
  font-size: 0.9rem;
  width: 120px;
  height: 5px;
}
.billProduct td input {
  border: none;
  width: 120px;
  border-color: lightgrey;
}
