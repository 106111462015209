.not-found-container {
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
}

.not-found-container-h1 {
    font-size: 6em;
    margin: 0;
    color: #ff6f61;
}

.not-found-container-p {
    font-size: 1.5em;
    margin: 10px 0 20px;
    color: #333;
}

.home-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #ff6f61;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.home-link:hover {
    background-color: #e65a50;
}