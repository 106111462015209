.outflowform-container {
  /* padding: 5px 20px 20px 30px; */
  padding: 10px 16px ;
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); */
}
.box-1 {
  display: flex;
  margin: 20px;
  justify-content: center;
  column-gap: 20px;
}
.box-2 {
  display: flex;
  margin: 20px;
  column-gap: 20px;
}
.box-3 {
  display: flex;
  margin: 20px;
  column-gap: 20px;
}
.box1-options {
  width: 200px;
  border-radius: 5px;
  /* border: 2px solid lightgrey; */
}

.box2-options {
  width: 190px;
  border-radius: 5px;
  /* border: 2px solid lightgrey; */
}

.box3-options {
  width: 200px;
  border-radius: 5px;
  /* border: 2px solid lightgrey; */
}
.outflowbill-totals {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  /* gap: 10px; */
  /* flex-direction: column; */
  /* width: 250px; */
  /* padding: 10px 16px; */
  /* margin: 0px 0px 20px 750px; */
}
.outflowbill-h1-tag {
  margin-left: 20px;
}
.outflowform-button-submit {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 10px;
  /* width: 180px; */
  padding: 10px 32px;
  /* margin-left: 820px; */
}
/* .outflow-totals-box { */
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px; */
/* } */
.outflow-totals-box label {
  font-size: 0.8rem;
  font-weight: 500;
}
.outflow-totals-box input {
  font-size: 0.8rem;
}
.outflowform-button-cancel {
  margin-right: 8px;
  font-size: 0.8rem;
  padding: 6px 16px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
}
.outflowform-cancel {
  margin-right: 10px;
  border: none;
  font-size: 0.8rem;
  padding: 6px 16px;
  color: #0056b3;
  box-shadow: 1px 1px 4px #0056b3;
  border-color: 3px solid #0056b3;
  background-color: white;
  border-radius: 4px;
}

.form-div{
width: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
gap: 2rem;
}

.form-div-field{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}
