.calendar-header {
  /* padding: 4px 8px; */
  display: flex;
  align-items: center;
}
.calender-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-logo {
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.calendar-title {
  margin-right: 20px;
  font-size: 1rem;
  color: #888; /* Adjust the color as needed */
  font-weight: 500;
}

.calendar-button {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  background-color: white;
  font-size: 0.8rem;
}

.calendar-icon-button {
  cursor: pointer;
  margin-left: 10px;
  background-color: white;
  border: 1px solid #ccc;
}
.material-icons-outlined {
  background-color: none;
  font-size: 16px;
  border: none;
  background-color: white;
}
.date {
  margin-left: 1rem; /* Equivalent to ml-4 (4 * 0.25rem = 1rem) */
  font-size: 1rem; /* Equivalent to text-xl */
  color: #888; /* Equivalent to text-gray-500 (adjust the color as needed) */
  font-weight: 500;
}
.button-1 {
  margin: 0px 12px 0px 12px;
}
.project-timer-div {
  padding: 1px;
}
.project-timer-div button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}
.project-timer-div-1 button:disabled {
  background-color: #ccc;
  /* color: grey; */
  cursor: not-allowed;
}

.project-timer-div select {
  padding: 5px;
  cursor: pointer;
}
.project-timer-div-1 {
  column-gap: 5px;
}
