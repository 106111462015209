.pointer {
    cursor: pointer;
}

.equal-space {
    padding: 20px 55px;
}

.btn-primary {
    background-color: #0056b3;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: #ffffff;
    cursor: pointer;
}


/* width  */
.w-100{
    max-width: 100%;
}
.fw-100{
    width: 100%;
}
/* height */
.h-100{
    max-height: 100%;
}

/* padding */
.px-5{
    padding: 0px 5px;
}
.px-10{
    padding: 0px 10px;
}
.px-20{
    padding: 0px 20px;
}
.px-30{
    padding: 0px 30px;
}
.py-5{
    padding: 5px 0px;
}
.py-10{
    padding: 10px 0px;
}
.py-20{
    padding: 20px 0px;
}
.py-30{
    padding: 30px 0px;
}


/* margin */
.mx-5{
    margin: 0px 5px;
}
.mx-10{
    margin: 0px 10px;
}
.mx-20{
    margin: 0px 20px;
}
.mx-30{
    margin: 0px 30px;
}
.my-5{
    margin: 5px 0px;
}
.my-10{
    margin: 10px 0px;
}
.my-20{
    margin: 20px 0px;
}
.my-30{
    margin: 30px 0px;
}

/* position */
.p-ab{
    position: absolute;
}
.p-re{
    position: relative;
}
.r-0{
    right: 0;
}
.l-0{
    left: 0;
}
.t-0{
    top: 0;
}
.b-0{
    bottom: 0;
}
.tac{
    text-align: center;
}
.fr{
    float: right;
}
.fl{
    float: left;
}
