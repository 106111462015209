.Refusal-page-container {
  padding: 1rem; 
}

.Refusal-bill-left-div {
  font-size: 1.8rem;
  font-weight: bold;
  /* margin: 15px; */
  width: 900px;
}

.Refusal-page-center-div textarea {
  width: 100%;
  height: 40vh;
  border: 1px solid #cccccc;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 0.0075em;
}

.Refusal-page-right-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.button1 {
  border: none;
  color: #0056b3;
  background-color: white;
  font-size: 0.9rem;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #0056b3;
  border: 1px solid #0056b3;
}

.button2 {
  margin-left: 10px;
  border: none;
  background-color: #0056b3;
  color: white;
  font-size: 0.9rem;
  padding: 9px;
  border-radius: 5px;
}