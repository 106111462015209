.outflowsplitbill-semi-container {
  display: flex;
  padding:10px;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}
.outflowsplitbill-rule-glcode {
  display: flex;
  gap: 6px;
}
.outflowsplitbill-rule-glcode label {
  font-size: 14px;
  color: grey;
  font-weight: 500;
}
.outflowsplitbill-select {
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 5px;
}
.outflowsplitbill-select-option {
  font-weight: 500;
}
.outflowsplitbill-cancel-confirm-button {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding: 0 10px 10px 0;
}
.outflowsplitbill-2buttons button {
  font-size: 0.8rem;
  padding: 8px;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  border: none;
}
.outflowsplitbill-semi-container select{
    width: 150px;
    /* border: 1px solid #6c69695c; */
    height: 30px;
    font-size: 12px;
    border-radius: 3px;
    /* color: #6c6969cf; */
}