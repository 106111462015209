.sidebar-container {
  padding: 0 40px 0 40px;
  background-color: white;
  /* margin: 10px; */
  height: 80vh;
  border-top: 1px solid #EEEEEE;
  /* box-shadow: 0px 0px px rgba(0, 0, 0, 0.5); */
  /* border-bottom-right-radius: 3px;
  border-bottom: 1pX solid #EEEEEE; */
}
.sidebar-container > div:hover {
  /* background-color: #EEEEEE; */
  /* width: 100%; */
  color: #0056b3;
  cursor: pointer;
}
.sidebar-item > div:hover {
  background-color: #EEEEEE;
}
h3 {
  font-weight: 400;
  font-size: 1rem;
}
.link-active{
  color:#0056b3;
  /* background: orange; */
}
.link{
  color:black
}
.sidebar-link {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .sidebar-container {
    padding: 0 40px; /* Add left padding for larger screens */
    /* width: 15%; */
  }
}
